import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import data from "../Data"
import character from "../assets/dude.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faJs, faPython } from "@fortawesome/free-brands-svg-icons";
import { faDatabase, faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";

export default function About(){
    return (
        <div className="page-container">
            <Header restrict={false}/>
            <section className="fade-transition loaded">
                <section className="about-me-section" style={{display:'flex', flexWrap:'wrap', justifyContent:'center', alignItems:'center',margin:'auto auto 3rem auto'}}>
                    <div style={{maxWidth:'800px'}}>
                        <h1 tabIndex="0" style={{fontWeight:'400', fontSize:'1.5rem', textDecoration:'underline'}}>
                            Background
                        </h1>
                        <p style={{margin:'auto auto 1rem auto'}}>
                            {data.personalDetails.biography.firstParagraph}
                        </p>
                        <p>
                            {data.personalDetails.biography.secondParagraph}
                        </p>
                    </div>
                    <img src={character} alt="cartoon depiction of Michael smiling" style={{width:'12rem', margin:'auto'}} />
                </section>
                <div role="presentation" className="divider"></div>
                <div style={{display:'flex', flexWrap:'wrap', justifyContent:'start'}}>
                    <section className="about-qualifications-section" style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', margin:'0 auto auto auto', paddingBottom:'2rem'}}>
                        <h1 tabIndex="0" style={{fontWeight:'400', fontSize:'1.5rem', textDecoration:'underline'}}>
                            Qualifications
                        </h1>
                        <ul style={{display:'flex', flexDirection:'column', justifyContent:'space-evenly', alignItems:'start', padding:'0 0 0 1rem', margin:'0 auto auto 0'}}>
                            {data.personalDetails.education.certifications.map((skill,index)=>(
                                <li key={index} style={{paddingBottom:'1.75rem'}}>
                                    {skill}
                                </li>
                            ))}
                        </ul>
                    </section>
                    <section className="about-technologies-section" style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:'0 auto auto auto', paddingBottom:'2rem'}}>
                        <h1 tabIndex="0" style={{fontWeight:'400', fontSize:'1.5rem', textDecoration:'underline'}}>
                            Technologies
                        </h1>
                        <div style={{display:'flex', justifyContent:'center', flexWrap:'wrap'}}>
                            <div className="technology-block">
                                <FontAwesomeIcon icon={faJs} className="technology-logo" />
                                <ul style={{padding:'0', marginBottom:'0'}}>
                                    {data.personalDetails.education.frontend.map((skill,index)=>(
                                        <li key={index} style={{marginBottom:'0.5rem', color:'white'}}>
                                            {skill}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="technology-block">
                                <FontAwesomeIcon icon={faPython} className="technology-logo" />
                                <ul style={{padding:'0', marginBottom:'0'}}>
                                    {data.personalDetails.education.backend.map((skill,index)=>(
                                        <li key={index} style={{marginBottom:'0.5rem', color:'white'}}>
                                            {skill}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="technology-block">
                                <FontAwesomeIcon icon={faDatabase} className="technology-logo" />
                                <ul style={{padding:'0', marginBottom:'0'}}>
                                    {data.personalDetails.education.database.map((skill,index)=>(
                                        <li key={index} style={{marginBottom:'0.5rem', color:'white'}}>
                                            {skill}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="technology-block">
                                <FontAwesomeIcon icon={faScrewdriverWrench} className="technology-logo"/>
                                <ul style={{padding:'0', marginBottom:'0'}}>
                                    {data.personalDetails.education.tools.map((skill,index)=>(
                                        <li key={index} style={{marginBottom:'0.5rem', color:'white'}}>
                                            {skill}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <Footer />
        </div>
    )
}