import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProjectsLayout from "../components/ProjectsLayout";

export default function Projects(){
    return (
        <div className="page-container">
            <Header restrict={false}/>
            <section className="fade-transition loaded" style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', margin:'auto auto 2.5rem auto'}}>
                <h1 style={{fontWeight:'400', fontSize:'1.5rem', textDecoration:'underline'}}>
                    Projects
                </h1>
                <ProjectsLayout />
            </section>
            <Footer />
        </div>
    )
}