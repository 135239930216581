import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import ProjectDisplay from "./pages/ProjectDisplay"
import Home from "./pages/Home"
import About from "./pages/About"
import Contact from "./pages/Contact"
import Projects from "./pages/Projects"

export default function App() {

  return (
    <div className="landing-section">
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/projects">
                  <Route index element={<Projects />} />
                  <Route path=":projectTitle" element={<ProjectDisplay />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </div>
  )
}
