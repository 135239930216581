const dataInfo =
    {
        socialMedia: {
                    email: 'contact@m-gagliardi.com',
                    github: "https://github.com/mikegag",
                    linkedIn: "https://www.linkedin.com/in/michael-gagliardi-b662b3188/",
                    twitter: "https://twitter.com/MikeCodesStuff"
        },

        projects: [
                {
                    id: "skillja",
                    link: "https://www.skillja.ca/",
                    title: "SkillJa (beta)",
                    image: "skillja.png",
                    description: "A platform that allows athletes to find sport coaches in their local area.",
                    technologies: ["React", "TypeScript", "Tailwind CSS", "Python-Django", "PostgreSQL", "Heroku"],
                    date: 'March 2024 - Present'
                },
                {
                    id: "jobCache",
                    link: "https://job-cache-server-d10d775041b7.herokuapp.com/",
                    title: "JobCache",
                    image: "jobcache.png",
                    description: "A job application tracking tool designed specifically for Developers.",
                    technologies: ["React", "Python-Flask", "MongoDB", "API's", "Heroku"],
                    date: 'March 2024 - May 2024',
                    mainProblem: "How can we create a personalized solution to aid developers during their job searching process?",
                    mainGoal: "To allow users to manage and update job applications in a single place.",
                    roles: "Lead Software Developer, lead UX designer, and UX researcher.",
                    researchSummary: "One assumption I made prior to conducting research was about the average user's past behavior. I believed users were utilizing some form of sophisticated software, likely paid, to track their job applications. However, after conducting four interviews and two surveys, I concluded that users often favored much simpler and cheaper programs, such as Microsoft Excel, to track applications. This was likely due to the fact that many users had prior experience with this software and preferred a free option, as many of them were unemployed during their job search.",
                    wireFramesSummary: "I wanted to create a simple yet polished UI, as users would possibly be using the application in a professional setting. I also wanted to focus on core functionality without adding too many extra features, so I limited the information architecture to just a few pages.",
                    painPoints: [
                        {
                            title: "Lack of Connection",
                            description: "Users found the UI and functionality of their existing solution to be very simple and dull, which made them less excited to use it on a daily basis."
                        },
                        {
                            title: "Lack of Features",
                            description: "Users were satisfied with their existing solution but often described its lack of features geared toward the job search process."
                        }
                    ],
                    usabilityFindings: [
                        "The home page needed more details or further explanation of the application's capabilities.",
                        "Users would like a variety of sources for the daily motivational quote.",
                        "Users expected a visual cue to represent the question loading state.",
                        "Users expected some form of feedback to indicate that an application was saved or updated successfully."
                    ],
                    accessibilityConcerns: [
                        "Provide general motivational quotes that can apply to a wide range of personas.",
                        "Ensure users have an easily accessible and credible source for external job postings."
                    ],
                    
                    conclusion: "This project deepened my understanding of accessibility by emphasizing the importance of clear and meaningful alt text and labels. I learned how to use these elements effectively to distinguish sections, convey intent, and enhance functionality for all users, including those relying on assistive technologies. This experience reinforced my commitment to designing inclusive digital experiences that prioritize clarity, usability, and accessibility."
                },
                {
                    id: "quizApp",
                    link: "https://quiz-app-neon-kappa.vercel.app/",
                    title: "Quizzical",
                    image: "quiz.png",
                    description: "A quiz app which tests your knowledge on a plethora of topics.",
                    technologies: ["HTML", "CSS", "Javascript", "React", "API's", "Vercel"],
                    date: 'Jan 2024 - Feb 2024',
                    painPoints: [
                        {
                            title: "Question Difficulty",
                            description: "Questions ranging across six or more categories caused users to feel unmotivated by the end of the game. Moving forward, questions will need to be easier and/or limited to fewer categories."
                        },
                        {
                            title: "Number of Questions Presented",
                            description: "Users felt overwhelmed when more than six questions were displayed on-screen at once. Moving forward, fewer than six questions will be presented during each game."
                        }
                    ],                    
                    mainProblem: "How can we create an engaging educational game that any user can play?",
                    mainGoal: "To educate user's about history, geography, and pop-culture in a non-traditional format.",
                    roles: "Lead Software Developer, lead UX designer, and UX researcher.",
                    researchSummary: "One assumption I made prior to conducting research was about the target demographic. I believed the application would be used only by those with a prior interest in gaming. However, after conducting five interviews and one survey, I concluded that prior interest in gaming had no correlation to user interest. The research also revealed a wide range of age demographics interested in the game, including users between 25 and 50 years old. This changed my assumption, leading me to believe that the game would appeal to working-class individuals who would play during breaks or slow periods at their workplace.",
                    wireFramesSummary: "I wanted to create a simple UI so users could focus solely on answering game questions. Since the emphasis was on simplicity, I did not see the need for multiple pages explaining the game, company background, etc.",
                    usabilityFindings: [
                        "The home page needs some motion and more visual appeal.",
                        "Users would like a rating system to represent the final score.",
                        "Four questions with four answers each was the optimal amount to satisfy users.",
                        "Users expected a visual cue to represent the question loading state.",
                        "For questions answered incorrectly, users would like both the incorrect and correct answers to be easily identifiable."
                    ],
                    accessibilityConcerns: [
                        "For a wide variety of users to enjoy the game, questions were used from a range of categories labeled as 'medium' difficulty.",
                        "To allow users to quickly start and exit the game without maintaining any sort of emotional connection to their score, a scoreboard/score-tracking feature was not included.",
                        "A simple color palette with smooth motion transitions was implemented to avoid overwhelming the user with unnecessary or additional information."
                    ],
                    
                    conclusion: "Through this project, I gained valuable experience in transforming user research into visually compelling prototypes using Figma. I learned how to apply user insights to create intuitive and aesthetically pleasing designs, ensuring that every design decision is rooted in real user needs. This process reinforced the importance of iterative design, usability testing, and refining prototypes based on feedback to enhance the overall user experience."
                }
        ],

        personalDetails: {
                    education: {
                        certifications: ["BA - Digital Media (York University)", 
                                        "B.Ed - Concurrent Education (York University)",
                                        "Ontario Certified Teacher (Ontario College of Teachers)",
                                        "Frontend Developer Bootcamp (Scrimba)",
                                        "Full Stack Developer Certificate (Toronto Metropolitan University)"],

                        skills: ["JavaScript", "React", "TypeScript", "Node.js", "Tailwind CSS", "Python", "Django", "Flask", "MongoDB", "PostgreSQL", "Heroku", "AWS"],
                        frontend: ["React", "TypeScript", "Node.js"],
                        backend: [ "Django", "Flask", 'Pandas'],
                        database: ["PostgreSQL", "MongoDB"],
                        tools: ["Figma", "Heroku"],

                        completeSkillSet: ["HTML5", "CSS", "Javascript ES6+", "NodeJS", "React", "TypeScript", "Tailwind", "Python", "Flask",
                                            "Django", "PostgreSQL", "MongoDB", "Git", "Github", "Heroku", "Vercel", "Figma", "MacOS", "Windows", "Linux-Ubuntu"],
                    },

                    biography: { 
                        firstParagraph:`Hey, I'm Mike! I'm a former Computer Science Teacher turned Full-Stack Developer 
                            based in Toronto. I began this journey back in 2023 and have loved every moment since! I enjoy crafting visually engaging, user-friendly web applications and solving 
                            complex problems through code.`,

                        secondParagraph: `Outside of coding some of my hobbies include running marathons, 
                        listening to audiobooks, golfing, & trying new cooking recipes. It's pretty hard to sum up all of my interests, skills, and other attributes in a short paragraph, so if you'd like to 
                        learn more about me feel free to explore the rest of my portfolio!`
                    },

                    email: "contact@m-gagliardi.com"
        }
    }

export default dataInfo