import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import GetWindowSize from "../hooks/GetWindowSize"
import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"

export default function Header({restrict}){
    const windowSize = GetWindowSize()
    const navigate = useNavigate()
    return (
        <header className="header-section">
            {restrict ?
                <>
                    <FontAwesomeIcon 
                        icon={faLongArrowLeft} 
                        className="header-arrow"
                        onClick={()=>navigate('../')}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                                navigate('../');
                            }
                        }}
                        tabIndex={0}
                    />
                    <img 
                        src={require('../assets/portfolio-favicon.png')} 
                        className="header-logo"
                        style={restrict && windowSize.width >850 ? { marginLeft: '10rem' } : {marginLeft:'1.5rem'}}
                        onClick={()=>navigate('/')}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                                navigate('/');
                            }
                        }}
                        tabIndex={0}
                    />
                    <p style={{margin:'auto 0 auto auto',opacity:'0', width:'30px'}}></p>
                </>
            :
                <>
                    <p 
                        onClick={()=>navigate('/')}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                                navigate('/');
                            }
                        }}
                        tabIndex={0}
                    >
                        Home
                    </p>
                    <p 
                        onClick={()=>navigate('/about')}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                                navigate('/about');
                            }
                        }}
                        tabIndex={0}
                    >
                        About
                    </p>
                    <img 
                        src={require('../assets/portfolio-favicon.png')} 
                        className="header-logo"
                        onClick={()=>navigate('/')}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                                navigate('/');
                            }
                        }}
                        tabIndex={0}
                    />
                    <p 
                        onClick={()=>navigate('/projects')}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                                navigate('/projects');
                            }
                        }}
                        tabIndex={0}
                    >
                        Projects
                    </p>
                    <p 
                        onClick={()=>navigate('/contact')}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                                navigate('/contact');
                            }
                        }}
                        tabIndex={0}
                    >
                        Contact
                    </p>
                </>
            }   
        </header>
    )
}