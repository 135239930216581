import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons"

export default function SingleProject(props) {  

    return (
        <div className="project-preview">
            <img src={props.image} alt={`${props.title}'s User Interface`} />
            <div className="project-preview-description">
                <h4>{props.title}</h4>
                <FontAwesomeIcon icon={faArrowTrendUp} />
            </div>
        </div>
    )
}
