import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import data from "../Data"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { Link } from "react-router-dom";

export default function Contact(){
    return (
        <div className="page-container">
            <Header restrict={false}/>
            <section className="fade-transition loaded">
                <div style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', margin:'auto auto 2.5rem auto'}}>
                    <h1 style={{fontWeight:'400', fontSize:'1.5rem', textDecoration:'underline'}}>
                        Contact Me
                    </h1>
                    <p style={{marginTop:'0'}}>
                        If you would like me to build out an idea of yours, feel free to reach out via email.
                    </p>
                </div>
                <section style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', margin:'auto auto 1.5rem auto', textAlign:'center'}}>
                    <h2 style={{fontSize:'1.25rem', fontWeight:'400'}}>
                        <FontAwesomeIcon icon={faMailBulk} style={{marginRight:'0.5rem'}}/>
                        Email:
                    </h2>
                    <a className="contact-link" href="mailto:contact@m-gagliardi.com">
                        {data.socialMedia.email}
                    </a>
                </section>
                <section style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', margin:'auto auto 1.5rem auto', textAlign:'center'}}>
                    <h2 style={{fontSize:'1.25rem', fontWeight:'400'}}>
                        <FontAwesomeIcon icon={faLinkedin} style={{marginRight:'0.5rem'}}/>
                        LinkedIn:
                    </h2>
                    <Link className="contact-link" to={`${data.socialMedia.linkedIn}`}>
                        {data.socialMedia.linkedIn}
                    </Link>
                </section>
                <section style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', margin:'auto auto 1.5rem auto', textAlign:'center'}}>
                    <h2 style={{fontSize:'1.25rem', fontWeight:'400'}}>
                        <FontAwesomeIcon icon={faGithub} style={{marginRight:'0.5rem'}}/>
                        Github:
                    </h2>
                    <Link className="contact-link" to={`${data.socialMedia.github}`}>
                        {data.socialMedia.github}
                    </Link>
                </section>
                <section style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', margin:'auto auto 1.5rem auto', textAlign:'center'}}>
                    <h2 style={{fontSize:'1.25rem', fontWeight:'400'}}>
                        <FontAwesomeIcon icon={faTwitter} style={{marginRight:'0.5rem'}}/>
                        Twitter:
                    </h2>
                    <Link className="contact-link" to={`${data.socialMedia.twitter}`}>
                        {data.socialMedia.twitter}
                    </Link>
                </section>
            </section>
            <Footer />
        </div>
    )
}