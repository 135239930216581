import React from "react"
import { useParams } from "react-router-dom"
import dataInfo from "../Data"
import Header from "../components/Header"
import Footer from "../components/Footer"

export default function ProjectDisplay() {
  const { projectTitle } = useParams() // Get project title from URL parameters
  const project = dataInfo.projects.find(project => project.id === projectTitle)


  return (
    <div className="page-container">
      <Header restrict={true}/>
      <section className="fade-transition loaded" style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center', margin:'auto auto 2.5rem auto'}}>
        <h1 style={{fontWeight:'400', fontSize:'1.5rem', textDecoration:'underline'}}>
            {project.title}
        </h1>

        <section className="project-overview-section" style={{display:'flex', flexWrap:'wrap', justifyContent:'center', margin:'auto auto 3rem auto'}}>
          <img 
            src={process.env.PUBLIC_URL + "/assets/" + project.image} 
            alt={`${project.title}'s UI`}
          />
          <div style={{display:'flex',flexDirection:'column', margin:'auto', padding:'0 1rem'}}>
            <a 
              target="_blank" 
              rel="noopener noreferrer" 
              href={project.link} 
              className="project-link"
              aria-label="view live link for project"
            >
                View Project
            </a>
            <p>Date: {project.date}</p>
            <p>Roles: {project.roles}</p>
            <p>Overview: {project.description}</p>
          </div>
        </section>
        {projectTitle !== 'skillja' ?
        <>
          <section className="project-user-research-section" style={{margin:'auto auto 4rem auto'}}>
            <h2 tabIndex="0" style={{fontWeight:'700', fontSize:"1rem", textDecoration:'underline'}}>
              Understanding The User:
            </h2>
            <div>
              <p>Problem: {project.mainProblem}</p>
              <p>Goal: {project.mainGoal}</p>
            </div>
            <div style={{display:'flex', flexWrap:'wrap'}}>
              <div style={{display:'flex',flexDirection:'column', justifyContent:'center', padding:'1rem'}}>
                <h3 style={{fontWeight:'400', fontSize:"1rem"}}>1. {project.painPoints[0].title}</h3>
                <p>{project.painPoints[0].description}</p>
              </div>
              <div style={{display:'flex',flexDirection:'column', justifyContent:'center', padding:'1rem'}}>
                <h3 style={{fontWeight:'400', fontSize:"1rem"}}>2. {project.painPoints[1].title}</h3>
                <p>{project.painPoints[1].description}</p>
              </div>
            </div>
            <div className="user-research-images" style={{display:'flex', flexWrap:'wrap'}}>
              <img src={require(`../assets/${project.id}/user-persona.png`)} style={{width:'340px', padding:'1rem'}} alt="user persona example"/>
              <img src={require(`../assets/${project.id}/user-journey.png`)} style={{width:'340px', padding:'1rem'}} alt="user journey map example"/>
            </div>
            <p style={{padding:'0.5rem'}}>
              {project.researchSummary}
            </p>
          </section>

          <section style={{margin:'auto auto 4rem auto'}}>
            <h2 tabIndex="0" style={{fontWeight:'700', fontSize:"1rem", textDecoration:'underline'}}>
              Prototypes & Mockups
            </h2>
            <div className="project-prototypes-section" style={{display:'flex',flexWrap:'wrap', justifyContent:'center'}}> 
              <img src={require(`../assets/${project.id}/paper-wireframes.jpg`)} style={{width:'350px', padding:'1rem', margin:'auto'}} alt="paper wireframes"/>
              <img className="phone-prototype" src={require(`../assets/${project.id}/low-fidelity-1.png`)} style={{width:'140px', height:'300px', padding:'1rem', margin:'auto'}} alt="low-fidelity example #1"/>
              <img className="phone-prototype" src={require(`../assets/${project.id}/low-fidelity-2.png`)} style={{width:'140px', height:'300px', padding:'1rem', margin:'auto'}} alt="low-fidelity example #2"/>
              <video autoPlay loop muted playsInline style={{ width:'350px', height:'300px', borderRadius: "20px", margin:'auto' }} >
                  <source src={require(`../assets/${project.id}/low-fidelity.mp4`)} type="video/mp4" />
                  Your browser does not support the video tag.
              </video>
              <p style={{margin:'4rem auto'}}>{project.wireFramesSummary}</p>
              <img className="phone-prototype" src={require(`../assets/${project.id}/mockup-1.png`)} style={{width:'140px', height:'300px', padding:'1rem'}} alt="mockup example #1"/>
              <img className="phone-prototype" src={require(`../assets/${project.id}/mockup-2.png`)} style={{width:'140px', height:'300px', padding:'1rem'}} alt="mockup example #2"/>
              <img className="phone-prototype" src={require(`../assets/${project.id}/mockup-3.png`)} style={{width:'140px', height:'300px', padding:'1rem'}} alt="mockup example #3"/>
              <img className="phone-prototype" src={require(`../assets/${project.id}/mockup-4.png`)} style={{width:'140px', height:'300px', padding:'1rem'}} alt="mockup example #4"/>
              <video autoPlay loop muted playsInline style={{ width:'350px', height:'300px', borderRadius: "20px", margin:'auto' }} >
                  <source src={require(`../assets/${project.id}/high-fidelity.mp4`)} type="video/mp4" />
                  Your browser does not support the video tag.
              </video>
            </div>
          </section>
          
          <section className="project-conclusion" style={{margin:'auto auto 4rem auto'}}>
            <h2 tabIndex="0" style={{fontWeight:'700', fontSize:"1rem", textDecoration:'underline'}}>
              Conclusion
            </h2>
            <div style={{display:'flex', flexWrap:'wrap'}}>
              <ul>
                {project.accessibilityConcerns.map((point,index)=>(
                  <li key={index}>{point}</li>
                ))}
              </ul>
              <p>{project.conclusion}</p>
            </div>
          </section>
        </>
        :
        <p tabIndex="0">Please contact me if you'd like to see more</p>
        } 
      </section>
      <Footer />
    </div>
  )
}
