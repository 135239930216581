import React, { useRef, useEffect, useState } from "react";
import backgroundVideo from "../assets/background-video.mp4";
import { TypeAnimation } from "react-type-animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function Home(){
    const [displayVideo, setDisplayVideo] = useState(true);
    const [loading, setLoading] = useState(true);
    const [hoveredElement, setHoveredElement] = useState(null);
    const videoRef = useRef(null);
    const textRef = useRef(null);
    const navigate = useNavigate();

    useEffect(()=>{
        const timer = setTimeout(() => {
            setLoading(false)
        }, 3000);
        return ()=>clearTimeout(timer);
    },[])

    return (
        <div className="video-container">
            {/* Main Background Video */}
            {displayVideo &&
                <video 
                    autoPlay 
                    loop 
                    muted 
                    playsInline 
                    className={`video-bg ${displayVideo ? "" : "video-hidden"} `} 
                    ref={videoRef} 
                    onMouseEnter={() => setHoveredElement("Video")}
                    onMouseLeave={() => setHoveredElement(null)}
                    poster="/background-poster.png"
                >
                    <source src={backgroundVideo} type="video/mp4" />
                </video>
            }

            <div className="content">
                {/* Text Animation */}
                {loading ? 
                    <TypeAnimation
                        sequence={["Welcome.", 1200]}
                        wrapper="h1"
                        speed={250}
                        repeat={0}
                        className="typing-text fade-transition loaded"
                    />
                :
                    <div className={`fade-transition ${!loading ? 'loaded' : ''}`} style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', margin:'auto'}}>
                        <img src={require('../assets/portfolio-favicon.png')} className="logo" alt="Portfolio logo"/>
                        <div 
                            style={{width:'280px'}}
                            onMouseEnter={() => {
                                setHoveredElement("About")
                                setDisplayVideo(false)
                            }}
                            onMouseLeave={() => {
                                setHoveredElement(null);
                                setDisplayVideo(true);
                            }}
                            ref={textRef}
                            aria-label="navigate to About page"
                            onClick={()=>navigate('/about')}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                    navigate('/about');
                                }
                            }}
                            tabIndex={0}
                        >
                            <h2 className="home-navigation-text">
                                {hoveredElement==='About' && <FontAwesomeIcon icon={faCircleRight} className="home-navigation-icon"/>}
                                About
                            </h2>
                        </div>
                        <div 
                            style={{width:'280px'}}
                            onMouseEnter={() => {
                                setHoveredElement("Projects")
                                setDisplayVideo(false)
                            }}
                            onMouseLeave={() => {
                                setHoveredElement(null)
                                setDisplayVideo(true)
                            }}
                            ref={textRef}
                            aria-label="navigate to Projects page"
                            onClick={()=>navigate('/projects')}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                    navigate('/projects');
                                }
                            }}
                            tabIndex={0}
                        >
                            <h2 className="home-navigation-text">
                                {hoveredElement==='Projects' && 
                                    <FontAwesomeIcon icon={faCircleRight} className="home-navigation-icon" />
                                }
                                Projects
                            </h2>
                        </div>
                        <div 
                            style={{width:'280px'}}
                            onMouseEnter={() => {
                                setHoveredElement("Contact")
                                setDisplayVideo(false)
                            }}
                            onMouseLeave={() => {
                                setHoveredElement(null)
                                setDisplayVideo(true)
                            }}
                            ref={textRef}
                            aria-label="navigate to Contact page"
                            onClick={()=>navigate('/contact')}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                    navigate('/contact');
                                }
                            }}
                            tabIndex={0}
                        >
                            <h2 className="home-navigation-text">
                                {hoveredElement==='Contact' && 
                                    <FontAwesomeIcon icon={faCircleRight} className="home-navigation-icon" style={{paddingBottom:'5.5rem'}}/>
                                }
                                Contact
                            </h2>
                        </div>
                        <p style={{position:'absolute', bottom:'1rem', fontSize:'1rem', color:'#282828'}}>
                            M.Gagliardi Portfolio | 2025
                        </p>
                    </div>
                }
            </div>
        </div>
    )
}