import React from "react"

export default function Footer(){
    return (
        <footer
            style={{margin:'5rem auto auto auto', width:'full', display:'flex', justifyContent:'center'}}
        >
            <p style={{color:'#736E5E'}}>M.Gagliardi Portfolio | 2025</p>
        </footer>
    )
}